$( document ).ready(function() {
  // SEARCH
  $("#search-magnifier").click(function() {
    $("#search-fullscreen").delay(110).fadeIn(function () {
      $( "#recherche" ).focus();
    });

  });
  $(".overlay-close").click(function() {
    $("#search-fullscreen").delay(110).fadeOut();
  });
});
